export const COLORS = [
    'rgba(0, 128, 0, 1)',
    'rgba(0, 255, 255, 1)',
    'rgba(0, 0, 255, 1)',
    'rgba(237, 125, 49, 1)',
    'rgba(148, 0, 211, 1)',
    'rgba(205, 55, 55, 1)',
    'rgba(255, 215, 0, 1)',
    'rgba(255, 0, 255, 1)',
    'rgba(51, 126, 195, 1)',
    'rgba(217, 164, 101, 1)',
    'rgba(0, 158, 0, 1)',
    'rgba(139, 0, 139, 1)',
    'rgba(0, 139, 139, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 140, 0, 1)',
    'rgba(0, 204, 0, 1)',
    'rgba(122, 82, 153, 1)',
    'rgba(238, 188, 29, 1)',
    'rgba(0, 206, 209, 1)',
    'rgba(139, 0, 0, 1)',
    'rgba(231, 84, 128, 1)',
    'rgba(17, 233, 115, 1)',
    'rgba(255, 255, 0, 1)',
    'rgba(0, 0, 139, 1)',
    'rgba(124, 124, 64, 1)',
    'rgba(255, 0, 0, 1)',
]

export const LOCATION_HIGHLIGHTED = '#dbe7f8'
export const LOCATION_UNHIGHLIGTHED = '#f7f7f7'

// Opacity values for IMUP selection/non selection in Paper.js
export const ACTIVE_OPACITY_VALUE = 0.8
export const INACTIVE_OPACITY_VALUE = 0.2

export const GRAY_400 = '#98A2B3'
export const GRAY_500 = '#667085'
export const GRAY_600 = '#475467'
export const GRAY_700 = '#344054'
export const WHITE = '#fff'
export const BLACK = '#000'

export const REGION_COLOR = '#0e6db6'

export const JOIST_COLOR = {
    WHITE: '#fff',
    BLACK: '#000',
    BLUE: '#005B96',
}

export const MASTER_SET_PLAN_COLOR = {
    OPTION: '#73E2A3',
    BASE_HOME: '#0E9384',
}
